import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import { BsCashStack, BsCheckCircle, BsClock, BsExclamationTriangle, BsGrid3X3GapFill, BsPersonAdd, BsPersonCheck, BsPieChartFill } from 'react-icons/bs';
import { Bar, BarChart, CartesianGrid, Legend, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useAuth } from '../../AuthContext';
import { post } from '../../services/api';
import './Dashboard.css';

function Dashboard() {
  const [analyticsData, setAnalyticsData] = useState({
    totalOrders: 0,
    completedOrders: 0,
    pendingOrders: 0,
    expiredOrders: 0,
    revenue: '',
    avgOrderValue: '',
    totalUsers: 0,
    activeUsers: 0,
  });
  const [orderData, setOrderData] = useState([]);
  const [revenueData, setRevenueData] = useState([]);
  const [userData, setUserData] = useState([]);
  const { adminData } = useAuth();

  useEffect(() => {
    const fetchAnalyticsData = async () => {
      try {
        const currentTime = Date.now();
        const response = await post('v1/analytics/getDashboardData', { currentTime, canteenIds: adminData.canteenId.map(id => parseInt(id)) });
        setAnalyticsData(response);
        setOrderData([
          { name: 'Total', orders: response.totalOrders },
          { name: 'Completed', orders: response.completedOrders },
          { name: 'Pending', orders: response.pendingOrders },
          { name: 'Expired', orders: response.expiredOrders },
        ]);
        setUserData([
          { name: 'Total Users', users: response.totalUsers },
          { name: 'New Users', users: response.activeUsers },
        ]);
      } catch (error) {
        console.error('Error fetching analytics data:', error);
      }
    };

    fetchAnalyticsData();
  }, [adminData]);

  return (
    <main className='main-container'>
      <div className='main-title'>
        <h3>DASHBOARD</h3>
      </div>
      <div className='main-cards'>
        <div className='card'>
          <div className='card-inner'>
            <h3>TOTAL ORDERS</h3>
            <BsGrid3X3GapFill className='card_icon' />
          </div>
          <h1><CountUp end={analyticsData.totalOrders} duration={2.5} separator="," /></h1>
        </div>
        <div className='card'>
          <div className='card-inner'>
            <h3>COMPLETED ORDERS</h3>
            <BsCheckCircle className='card_icon' />
          </div>
          <h1><CountUp end={analyticsData.completedOrders} duration={2.5} separator="," /></h1>
        </div>
        <div className='card'>
          <div className='card-inner'>
            <h3>PENDING ORDERS</h3>
            <BsClock className='card_icon' />
          </div>
          <h1><CountUp end={analyticsData.pendingOrders} duration={2.5} separator="," /></h1>
        </div>
        <div className='card'>
          <div className='card-inner'>
            <h3>EXPIRED ORDERS</h3>
            <BsExclamationTriangle className='card_icon' />
          </div>
          <h1><CountUp end={analyticsData.expiredOrders} duration={2.5} separator="," /></h1>
        </div>
        <div className='card'>
          <div className='card-inner'>
            <h3>REVENUE</h3>
            <BsPieChartFill className='card_icon' />
          </div>
          <h1>₹<CountUp end={parseFloat(analyticsData.revenue)} duration={2.5} separator="," decimals={2}/></h1>
        </div>
        <div className='card'>
          <div className='card-inner'>
            <h3>AVG ORDER VALUE</h3>
            <BsCashStack className='card_icon' />
          </div>
          <h1>₹<CountUp end={parseFloat(analyticsData.avgOrderValue)} duration={2.5} separator="," decimals={3} /></h1>
        </div>
        <div className='card'>
          <div className='card-inner'>
            <h3>TOTAL USERS</h3>
            <BsPersonCheck className='card_icon' />
          </div>
          <h1><CountUp end={analyticsData.totalUsers} duration={2.5} separator="," /></h1>
        </div>
        <div className='card'>
          <div className='card-inner'>
            <h3>ACTIVE USERS</h3>
            <BsPersonAdd className='card_icon' />
          </div>
          <h1><CountUp end={analyticsData.activeUsers} duration={2.5} separator="," /></h1>
        </div>
      </div>

      <div className='charts'>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={orderData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="orders" fill="#ff6000" />
          </BarChart>
        </ResponsiveContainer>

        {/* <ResponsiveContainer width="100%" height={300}>
          <LineChart data={revenueData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="revenue" stroke="#ff6000" />
          </LineChart>
        </ResponsiveContainer> */}

        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie data={userData} dataKey="users" nameKey="name" cx="50%" cy="50%" outerRadius={80} fill="#ff6000" label />
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </main>
  );
}

export default Dashboard;
