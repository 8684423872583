import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import bg from '../images/login_bg.jpg';
import { get, post } from '../services/api';
import './AdminLogin.css';

function AdminLogin() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { setAuthData, setAdminData } = useAuth();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError('');

    try {
      const currentTime = Date.now();
      console.log('Login request:', { email, password, currentTime });
      const response = await post('v1/adminauth/login', {
        email,
        password,
        currentTime,
      });
      console.log('Login response:', response);

      if (response.token) {
        const { token, adminId } = response;
        setAuthData({ token, adminId });
        localStorage.setItem('authToken', token);
        await fetchAndSaveAdminData(adminId);
        navigate('/');
      } else {
        setError('Login failed: ' + response.message);
      }
    } catch (error) {
      console.error('Login error:', error);
      setError('Error during login: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchAndSaveAdminData = async (adminId) => {
    try {
      const adminData = await get(`v1/admins/${adminId}`);
      console.log('Admin data:', adminData);
      setAdminData(adminData);
      localStorage.setItem('adminData', JSON.stringify(adminData));
      navigate('/admin-panel');
    } catch (error) {
      console.error('Error fetching admin data:', error);
      setError('Error fetching admin data');
    }
  };

  return (
    <div className="admin-page">
      <div className="background-image">
        <img src={bg} alt="Happy Pets" />
      </div>

      <div className="overlay">
        <div className="login-container">
          <h2>Admin Login</h2>
          <form onSubmit={handleSubmit}>
            <input
              type="email"
              placeholder="Email ID"
              className="login-input"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              type="password"
              placeholder="Password"
              className="login-input"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <button type="submit" className="login-button" disabled={loading}>
              {loading ? 'Logging in...' : 'Login'}
            </button>
          </form>
          {error && <p className="error-message">{error}</p>}
        </div>
      </div>
    </div>
  );
}

export default AdminLogin;
