import React, { createContext, useContext, useEffect, useState } from 'react';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [authData, setAuthData] = useState(() => {
    const token = localStorage.getItem('authToken');
    const adminId = localStorage.getItem('adminId');
    return { token, adminId };
  });

  const [adminData, setAdminData] = useState(() => {
    const storedAdminData = localStorage.getItem('adminData');
    return storedAdminData ? JSON.parse(storedAdminData) : null;
  });

  useEffect(() => {
    if (authData.token) {
      localStorage.setItem('authToken', authData.token);
      localStorage.setItem('adminId', authData.adminId);
    } else {
      localStorage.removeItem('authToken');
      localStorage.removeItem('adminId');
    }
  }, [authData]);

  useEffect(() => {
    if (adminData) {
      localStorage.setItem('adminData', JSON.stringify(adminData));
    } else {
      localStorage.removeItem('adminData');
    }
  }, [adminData]);

  const value = {
    authData,
    setAuthData,
    adminData,
    setAdminData,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
