import React, { useEffect, useState } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import ReactPaginate from 'react-paginate';
import { useAuth } from '../../AuthContext';
import { post, put } from '../../services/api';
import SearchBar from '../SearchBar';
import PrintKOTModal from '../KOT/PrintKOTModel.jsx';
import './KOT.css';

function KOT() {
  const [orders, setOrders] = useState([]);
  const [pastOrders, setPastOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCanteenId, setSelectedCanteenId] = useState('All');
  const [hoveredOrder, setHoveredOrder] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [showPrintKOTModal, setShowPrintKOTModal] = useState(false);
  const [showRePrintKOTModal, setShowRePrintKOTModal] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const itemsPerPage = 10;
  const { adminData } = useAuth();

  const canteenList = adminData
    ? adminData.canteenId.map((id, index) => ({
      canteenId: id,
      canteenName: adminData.canteenName[index]
    }))
    : [];

  const handleCanteenChange = (event) => {
    setSelectedCanteenId(event.target.value);
  };

  const filteredOrders = orders.filter((order) =>
    Object.values(order).some(
      (value) =>
        typeof value === 'string' && value.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const pageCount = Math.ceil(filteredOrders.length / itemsPerPage);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleSearch = (value) => {
    setSearchTerm(value);
    setCurrentPage(0); // Reset to first page when searching
  };

  const fetchOrders = async () => {
    setCurrentPage(0);
    try {
      let requestBody = {};
      if (selectedCanteenId === 'All') {
        requestBody = { canteenIds: adminData.canteenId.map(id => parseInt(id)) };
      } else {
        requestBody = { canteenIds: [parseInt(selectedCanteenId)] };
      }

      let endpoint = 'v1/kot/getActiveKOT';

      const response = await post(endpoint, requestBody);
      if (response) {
        setOrders(response);
        setHoveredOrder(response[0]);
      } else {
        console.error('Response data is undefined:', response);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching orders:', error);
      setIsLoading(false);
    }
  };

  const fetchPastOrders = async () => {
    setIsLoading(true);
    try {
      let requestBody = {};
      if (selectedCanteenId === 'All') {
        requestBody = { canteenIds: adminData.canteenId.map(id => parseInt(id)) };
      } else {
        requestBody = { canteenIds: [parseInt(selectedCanteenId)] };
      }

      const endpoint = 'v1/kot/getExpiredKOT';
      const response = await post(endpoint, requestBody);
      if (response) {
        setPastOrders(response);
        setHoveredOrder(response[0]);
        setShowRePrintKOTModal(true); // Show the modal
      } else {
        console.error('Response data is undefined:', response);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching past orders:', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, [selectedCanteenId, adminData.canteenId]);

  const formatDateToOnlyTime = (timestamp) => {
    const date = new Date(timestamp);
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours || 12;
    const formattedHours = String(hours).padStart(2, '0');
    return `${formattedHours}:${minutes} ${ampm}`;
  };

  const formatDateToDisplayWithTime = (timestamp) => {
    const date = new Date(timestamp);
    const formattedDate = date.toLocaleDateString(); // Adjust date format as needed
    const formattedTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    return `${formattedDate} ${formattedTime}`;
  };

  const handleRowHover = (order) => {
    setHoveredOrder(order);
  };

  const handlePrintKOTClick = () => {
    setShowPrintKOTModal(true);
  };

  const handleRePrintKOTClick = () => {
    fetchPastOrders(); // Fetch past orders
  };

  const handleCloseModal = () => {
    setShowPrintKOTModal(false);
    setShowRePrintKOTModal(false);
  };

  const handleDeliverClick = () => {
    setShowConfirmation(true);
  };

  const handleCancelDeliver = () => {
    setShowConfirmation(false);
  };

  const confirmDeliver = async () => {
    const orderId = hoveredOrder?.orderId; // Get the current hovered order's ID
    const deliveryTime = Date.now(); // Current timestamp in milliseconds
    const adminId = localStorage.getItem('adminId') || adminData?.adminId; // Get adminId from localStorage or context

    // Create request body
    const requestBody = {
      orderId,
      undeliveredItemIds: [], // Empty list as you mentioned
      deliveryTime,
      adminId
    };

    try {
      // Make PUT request to deliver the order
      const response = await put('/v1/orders/', requestBody);

      if (response.status === 200) {
        // Success - handle accordingly (e.g., fetch orders again)
        console.log('Order delivered successfully:', response.data);
        setShowConfirmation(false); // Close the confirmation dialog
        // Optionally refetch the orders or update UI
      } else {
        console.error('Failed to deliver order:', response);
      }
    } catch (error) {
      console.error('Error delivering order:', error);
    }
  };

  return (
    <div className="kot-container">
      <h2>Orders</h2>
      <div className="search-bar">
        <SearchBar onSearch={handleSearch} />
      </div>
      {isLoading ? (
        <p>Loading orders...</p>
      ) : (
        <>
          <div className='kot-top-area'>
            <select className='kot-canteen-dropdown' onChange={handleCanteenChange} value={selectedCanteenId}>
              <option value="All">All Canteens</option>
              {canteenList.map((canteen) => (
                <option key={canteen.canteenId} value={canteen.canteenId}>
                  {canteen.canteenName}
                </option>
              ))}
            </select>
            <div className="kot-tab-bar">
              <button className='expired-kot' onClick={handleRePrintKOTClick}>
                Reprint KOT
              </button>
              <button className='active-kot' onClick={handlePrintKOTClick}>
                Print KOT
              </button>
            </div>
          </div>
          <div className="kot-content">
            <div className="kot-table-container">
              <table>
                <thead>
                  <tr>
                    <th>Order ID</th>
                    <th>KOT No</th>
                    <th>Name</th>
                    <th>Ordered Time</th>
                    <th>Canteen Name</th>
                    <th>Total Price</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredOrders
                    .slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage)
                    .map((order, index) => (
                      <tr
                        key={order.orderId}
                        onMouseEnter={() => handleRowHover(order)}
                        className={index % 2 === 0 ? 'even-row' : 'odd-row'}
                      >
                        <td>{order.orderId}</td>
                        <td>{order.kotNumber}</td>
                        <td>{order.userName}</td>
                        <td>{formatDateToOnlyTime(order.orderedTime)}</td>
                        <td>{order.canteenName}</td>
                        <td>{order.totalPrice}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <ReactPaginate
                previousLabel={<FaChevronLeft />}
                nextLabel={<FaChevronRight />}
                breakLabel={'...'}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName={'pagination'}
                activeClassName={'active'}
                forcePage={currentPage}
              />
            </div>
            <div className="kot-details-container">
              {hoveredOrder ? (
                <div className="kot-order-details">
                  <h2>Order Details</h2>
                  <h3>Order ID: {hoveredOrder.orderId}</h3>
                  <h3>KOT No: {hoveredOrder.kotNumber}</h3>
                  <h3>User: {hoveredOrder.userName}</h3>
                  <h3>Ordered Time: {formatDateToDisplayWithTime(hoveredOrder.orderedTime)}</h3>
                  <h3>Total Price: {hoveredOrder.totalPrice}</h3>
                  {hoveredOrder.isParcel && <h3>(PARCEL)</h3>}
                  <table>
                    <thead>
                      <tr>
                        <th>Food Name</th>
                        <th>Food Price</th>
                        <th>Food Quantity</th>
                        <th>Food Category</th>
                      </tr>
                    </thead>
                    <tbody>
                      {hoveredOrder.foodItems.map((item) => (
                        <tr key={item.menuItemId}>
                          <td>{item.foodName}</td>
                          <td>{item.foodPrice}</td>
                          <td>{item.foodQuantity}</td>
                          <td>{item.foodCategory}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <button onClick={handleDeliverClick}>Deliver</button>
                </div>
              ) : (
                <p>No new orders</p>
              )}
            </div>
            {showConfirmation && (
              <div className="confirmation-dialog">
                <p>Are you sure you want to mark this order as delivered?</p>
                <button onClick={confirmDeliver}>Yes</button>
                <button onClick={handleCancelDeliver}>No</button>
              </div>
            )}
            {showRePrintKOTModal && (
              <PrintKOTModal orders={pastOrders} onClose={handleCloseModal} fetchOrders={fetchPastOrders} />
            )}
          </div>
          {showPrintKOTModal && (
            <PrintKOTModal orders={filteredOrders} onClose={handleCloseModal} fetchOrders={fetchOrders} />
          )}
        </>
      )}
    </div>
  );
}

export default KOT;
