import axios from 'axios';
// Set the base URL for the API
const API_URL = process.env.REACT_APP_API_URL;

// Function to get the token from local storage
const getToken = () => localStorage.getItem('authToken');

// Function to set up the headers with the authorization token
const getHeaders = () => {
  const token = getToken();
  return token ? { Authorization: `Bearer ${token}` } : {};
};

// General GET request
export const get = async (endpoint) => {
  try {
    const response = await axios.get(`${API_URL}${endpoint}`, { headers: getHeaders() });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

// General POST request
export const post = async (endpoint, data) => {
  try {
    const response = await axios.post(`${API_URL}${endpoint}`, data, { headers: getHeaders() });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

// General PUT request
export const put = async (endpoint, data) => {
  try {
    const response = await axios.put(`${API_URL}${endpoint}`, data, { headers: getHeaders() });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

// General DELETE request
export const del = async (endpoint) => {
  try {
    const response = await axios.delete(`${API_URL}${endpoint}`, { headers: getHeaders() });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

// Handle errors
const handleError = (error) => {
  console.error('API Error:', error);
  throw error;
};
