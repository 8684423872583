import React, { useState } from 'react';
import './SearchBar.css';

const SearchBar = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
    onSearch(event.target.value);
  };

  return (
    <div className="search-containerr">
      <input
        type="text"
        className="search-inputt"
        placeholder="Search..."
        value={searchTerm}
        onChange={handleChange}
      />
      {/* If you want to include a search button */}
      {/* <button className="search-button">Search</button> */}
    </div>
  );
};

export default SearchBar;