import React, { useEffect, useState } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import ReactPaginate from 'react-paginate';
import { useAuth } from '../../AuthContext';
import { post, put } from '../../services/api';
import SearchBar from '../SearchBar';
import './Orders.css';

function Orders() {
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCanteenId, setSelectedCanteenId] = useState('All');
  const [selectedTab, setSelectedTab] = useState('pending');
  const [hoveredOrder, setHoveredOrder] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const itemsPerPage = 10;
  const { adminData } = useAuth();

  const canteenList = adminData
    ? adminData.canteenId.map((id, index) => ({
        canteenId: id,
        canteenName: adminData.canteenName[index]
      }))
    : [];

  const handleCanteenChange = (event) => {
    setSelectedCanteenId(event.target.value);
  };

  const filteredOrders = orders.filter((order) =>
    Object.values(order).some(
      (value) =>
        typeof value === 'string' && value.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const pageCount = Math.ceil(filteredOrders.length / itemsPerPage);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleSearch = (value) => {
    setSearchTerm(value); //TODO: If the search term is not matched, query an endpoint - also add a virtual keyboard.
    setCurrentPage(0); // Reset to first page when searching
  };

  useEffect(() => {
    const fetchOrders = async () => {
      setCurrentPage(0);
      try {
        let requestBody = {};
        if (selectedCanteenId === 'All') {
          requestBody = { canteenIds: adminData.canteenId.map(id => parseInt(id)) };
        } else {
          requestBody = { canteenIds: [parseInt(selectedCanteenId)] };
        }

        let endpoint = '';
        if (selectedTab === 'completed') {
          endpoint = 'v1/canteens/admin/completedOrders';
        } else if (selectedTab === 'pending') {
          requestBody.currentTime = Date.now();
          endpoint = 'v1/canteens/admin/pendingOrders';
        } else if (selectedTab === 'expired') {
          requestBody.currentTime = Date.now();
          endpoint = 'v1/canteens/admin/expiredOrders';
        }

        const response = await post(endpoint, requestBody);
        if (response) {
          setOrders(response);
          setHoveredOrder(response[0]); // Set the first order as the default hovered order
        } else {
          console.error('Response data is undefined:', response);
        }
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching orders:', error);
        setIsLoading(false);
      }
    };

    fetchOrders();
  }, [selectedCanteenId, adminData.canteenId, selectedTab]);

  const formatDateToDisplay = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const formatDateToDisplayWithTime = (timestamp) => {
    const date = new Date(timestamp);
    const formattedDate = date.toLocaleDateString(); // Adjust date format as needed
    const formattedTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    return `${formattedDate} ${formattedTime}`;
  };

  const handleRowHover = (order) => {
    setHoveredOrder(order);
  };

  
  const handleDeliverClick = () => {
    setShowConfirmation(true);
  };

  const handleCancelDeliver = () => {
    setShowConfirmation(false);
  };

  const confirmDeliver = async () => {
    const orderId = hoveredOrder?.orderId; // Get the current hovered order's ID
    const deliveryTime = Date.now(); // Current timestamp in milliseconds
    const adminId = localStorage.getItem('adminId') || adminData?.adminId; // Get adminId from localStorage or context

    // Create request body
    const requestBody = {
      orderId,
      undeliveredItemIds: [], // Empty list as you mentioned
      deliveryTime,
      adminId
    };

    try {
      // Make PUT request to deliver the order
      const response = await put('/v1/orders/', requestBody);

      if (response) {
        // Success - handle accordingly (e.g., fetch orders again)
        console.log('Order delivered successfully:', response.data);
        setShowConfirmation(false); // Close the confirmation dialog
        // Optionally refetch the orders or update UI
      } else {
        console.error('Failed to deliver order:', response);
      }
    } catch (error) {
      console.error('Error delivering order:', error);
    }
  };

  
  return (
    <div className="orders-container">
      <h2>Orders</h2>
      <div className="search-bar">
        <SearchBar onSearch={handleSearch} />
      </div>
      {isLoading ? (
        <p>Loading orders...</p>
      ) : (
        <>
          <div className='orders-top-area'>
            <select className='canteen-dropdown' onChange={handleCanteenChange} value={selectedCanteenId}>
              <option value="All">All Canteens</option>
              {canteenList.map((canteen) => (
                <option key={canteen.canteenId} value={canteen.canteenId}>
                  {canteen.canteenName}
                </option>
              ))}
            </select>
            <div className="tab-bar">
              <button className={`tab-button ${selectedTab === 'pending' ? 'active' : ''}`} onClick={() => setSelectedTab('pending')}>
                Pending Orders
              </button>
              <button className={`tab-button ${selectedTab === 'completed' ? 'active' : ''}`} onClick={() => setSelectedTab('completed')}>
                Completed Orders
              </button>
              <button className={`tab-button ${selectedTab === 'expired' ? 'active' : ''}`} onClick={() => setSelectedTab('expired')}>
                Expired Orders
              </button>
            </div>
          </div>
          <div className="orders-content">
            <div className="orders-table-container">
              <table>
                <thead>
                  <tr>
                    <th>Order ID</th>
                    <th>User Name</th>
                    <th>KOT No</th>
                    <th>Canteen Name</th>
                    <th>Ordered Time</th>
                    <th>Total Price</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredOrders
                    .slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage)
                    .map((order, index) => (
                      <tr
                        key={order.orderId}
                        onMouseEnter={() => handleRowHover(order)}
                        className={index % 2 === 0 ? 'even-row' : 'odd-row'}
                      >
                        <td>{order.orderId}</td>
                        <td>{order.userName}</td>
                        <td>{order.kotNumber}</td>
                        <td>{order.canteenName}</td>
                        <td>{formatDateToDisplay(order.orderedTime)}</td>
                        <td>{order.totalPrice}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <ReactPaginate
                previousLabel={<FaChevronLeft />}
                nextLabel={<FaChevronRight />}
                breakLabel={'...'}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName={'pagination'}
                activeClassName={'active'}
                forcePage={currentPage}
              />
            </div>
            <div className="order-details-container">
              {hoveredOrder ? (
                <div className="order-details">
                  <h2>Order Details</h2>
                  <h3>Order ID: {hoveredOrder.orderId}</h3>
                  <h3>KOT No: {hoveredOrder.kotNumber}</h3>
                  <h3>User: {hoveredOrder.userName}</h3>
                  <h3>Ordered Time: {formatDateToDisplayWithTime(hoveredOrder.orderedTime)}</h3>
                  <h3>Total Price: {hoveredOrder.totalPrice}</h3>
                  {hoveredOrder.isParcel && <h3>(PARCEL)</h3>}
                  <table>
                    <thead>
                      <tr>
                        <th>Food Name</th>
                        <th>Food Price</th>
                        <th>Food Quantity</th>
                        <th>Food Category</th>
                        <th>Delivered</th>
                        <th>Delivered Time</th>
                        <th>Delivered By</th>
                      </tr>
                    </thead>
                    <tbody>
                      {hoveredOrder.foodItems.map((item) => (
                        <tr key={item.menuItemId}>
                          <td>{item.foodName}</td>
                          <td>{item.foodPrice}</td>
                          <td>{item.foodQuantity}</td>
                          <td>{item.foodCategory}</td>
                          <td>{item.isOrderDelivered ? 'Yes' : 'No'}</td>
                          <td>{formatDateToDisplayWithTime(item.deliveryTime)}</td>
                          <td>{item.deliveredByName}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <button onClick={handleDeliverClick}>Deliver</button>
                </div>
              ) : (
                <p>No order selected</p>
              )}
            </div>
            {showConfirmation && (
              <div className="confirmation-dialog">
                <p>Are you sure you want to mark this order as delivered?</p>
                <button onClick={confirmDeliver}>Yes</button>
                <button onClick={handleCancelDeliver}>No</button>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default Orders;
