import React, { useState } from 'react';
import { BsArchiveFill, BsBarChartFill, BsCardList, BsChatDotsFill, BsGrid1X2Fill, BsListCheck, BsPersonDash, BsPersonFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom'; // Import for navigation
import logo from '../../images/eatzaplogosmall.png';
import './Sidebar.css';
import { RiFilePdfFill } from 'react-icons/ri';
import { GrDocument } from 'react-icons/gr';

function Sidebar({ openSidebarToggle, OpenSidebar }) {
  const [activeLink, setActiveLink] = useState(''); // State to track active link

  const navigate = useNavigate(); // Utilize useNavigate hook

  const handleClick = (link) => {
    setActiveLink(link); // Update active link on click
  
    // Render appropriate component based on link
    switch (link) {
      case 'dashboard':
        navigate('/');
        break;
      case 'orders':
        navigate('/orders');
        break;
      case 'menu':
        navigate('/menu');
        break;
      case 'kot':
        navigate('/kot');
        break;
      case 'categories':
        navigate('/categories');
        break;
      case 'daily-reports':
        navigate('/daily-reports');
        break;
      case 'analytics':
        navigate('/analytics');
        break;
      case 'admins':
        navigate('/admins');
        break;
      case 'contacts':
        navigate('/contacts');
        break;
      case 'profile':
        navigate('/profile');
        break;
      // case 'help':
      //   navigate('/admin-panel/help');
      //   break;
      default:
        navigate('/');
    }
  };

  return (
    <aside id="sidebar" className={openSidebarToggle ? "sidebar-responsive" : ""}>
      <div className='sidebar-title'>
        <div className='sidebar-brand'>
          <div id='eatzap-logo'>
            <img src={logo} alt="eatzap logo" />
          </div>
          <div id='eatzap-text'>
            EATZAP
          </div>
        </div>
        <span className='icon close_icon' onClick={OpenSidebar}>X</span>
      </div>

      <ul className='sidebar-list'>
        <li className={`sidebar-list-item ${activeLink === 'dashboard' ? 'active' : ''}`} onClick={() => handleClick('dashboard')}>
          <a>
            <BsGrid1X2Fill className='icon' /> Dashboard
          </a>
        </li>
        <li className={`sidebar-list-item ${activeLink === 'orders' ? 'active' : ''}`} onClick={() => handleClick('orders')}>
          <a>
            <BsArchiveFill className='icon' /> Orders
          </a>
        </li>
        <li className={`sidebar-list-item ${activeLink === 'menu' ? 'active' : ''}`} onClick={() => handleClick('menu')}>
          <a>
            <BsCardList className='icon' /> Menu
          </a>
        </li>
        <li className={`sidebar-list-item ${activeLink === 'kot' ? 'active' : ''}`} onClick={() => handleClick('kot')}>
          <a>
            <GrDocument className='icon' /> KOT
          </a>
        </li>
        <li className={`sidebar-list-item ${activeLink === 'categories' ? 'active' : ''}`} onClick={() => handleClick('categories')}>
          <a>
            <BsListCheck className='icon' /> Categories
          </a>
        </li>
        <li className={`sidebar-list-item ${activeLink === 'daily-reports' ? 'active' : ''}`} onClick={() => handleClick('daily-reports')}>
          <a>
            <RiFilePdfFill className='icon' /> Daily Reports
          </a>
        </li>
        <li className={`sidebar-list-item ${activeLink === 'analytics' ? 'active' : ''}`} onClick={() => handleClick('analytics')}>
          <a>
            <BsBarChartFill className='icon' /> Analytics
          </a>
        </li>
        <li className={`sidebar-list-item ${activeLink === 'admins' ? 'active' : ''}`} onClick={() => handleClick('admins')}>
          <a>
            <BsPersonFill className='icon' /> Admins
          </a>
        </li>
        <li className={`sidebar-list-item ${activeLink === 'contacts' ? 'active' : ''}`} onClick={() => handleClick('contacts')}>
          <a>
            <BsChatDotsFill className='icon' /> Contact Details
          </a>
        </li>
        <li className={`sidebar-list-item ${activeLink === 'profile' ? 'active' : ''}`} onClick={() => handleClick('profile')}>
          <a>
            <BsPersonDash className='icon' /> Profile
          </a>
        </li>
        {/* <li className={`sidebar-list-item ${activeLink === 'help' ? 'active' : ''}`} onClick={() => handleClick('help')}>
          <a>
            <BsQuestionCircleFill className='icon' /> Help
          </a>
        </li> */}
      </ul>
    </aside>
  );
}

export default Sidebar;