import { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Admins from '../components/Admins/Admins';
import Analytics from '../components/Analytics/Analytics';
import Categories from '../components/Category/Category';
import Contacts from '../components/Contact/Contact';
import Dashboard from '../components/Dashboard/Dashboard';
import Header from '../components/Header/Header';
import Orders from '../components/Orders/Orders';
// import Help from '../components/Help/Help'; // Import Help component
import Menu from '../components/Menu/Menu';
import KOT from '../components/KOT/KOT';
import DailyReports from '../components/DailyReports/DailyReports';
import Profile from '../components/Profile/Profile';
import Sidebar from '../components/Sidebar/Sidebar';
import './AdminPanel.css';

function AdminPanel() {
  const [openSidebarToggle, setOpenSidebarToggle] = useState(false);

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  return (
    <div className="grid-container">
      <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@700&family=Roboto:wght@400;700&family=Open+Sans:wght@400;700&family=Lato:wght@400;700&family=Montserrat:wght@400;700&family=Nunito:wght@400;700&family=Raleway:wght@400;700&family=Ubuntu:wght@400;700&family=Work+Sans:wght@400;700&family=Merriweather:wght@400;700&display=swap" rel="stylesheet"></link>
      <Header OpenSidebar={OpenSidebar} />
      <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar} />
      <div className="main-container">
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="menu" element={<Menu />} />
          <Route path="orders" element={<Orders />} />
          <Route path="kot" element={<KOT />} />
          <Route path="categories" element={<Categories />} />
          <Route path="daily-reports" element={<DailyReports />} />
          <Route path="analytics" element={<Analytics />} />
          <Route path="admins" element={<Admins />} />
          <Route path="contacts" element={<Contacts />} />
          <Route path="profile" element={<Profile />} />
          { /*<Route path="help" element={<Help />} /> */}
          <Route path="*" element={<Dashboard />} />
        </Routes>
      </div>
    </div>
  );
}

export default AdminPanel;
