import React, { useEffect, useState } from 'react';
import { BiEdit, BiTrash } from 'react-icons/bi';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import ReactPaginate from 'react-paginate';
import { useAuth } from '../../AuthContext';
import { post, put } from '../../services/api';
import SearchBar from '../SearchBar';
import AddMenuForm from './AddMenuForm';
import EditMenuForm from './EditMenuForm';
import './Menu.css';

function Menu() {
  const [menuItems, setMenuItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCanteenId, setSelectedCanteenId] = useState('All');
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 5;
  const { adminData } = useAuth();

  const canteenList = adminData
    ? adminData.canteenId.map((id, index) => ({
        canteenId: id,
        canteenName: adminData.canteenName[index]
      }))
    : [];

  const handleCanteenChange = (event) => {
    setSelectedCanteenId(event.target.value);
  };

  const handleSearch = (value) => {
    setSearchTerm(value);
    setCurrentPage(0); // Reset to first page when searching
  };

  useEffect(() => {
    const fetchMenuItems = async () => {
      setCurrentPage(0);
      try {
        let requestBody = {};
        if (selectedCanteenId === 'All') {
          requestBody = { canteenIds: adminData.canteenId.map(id => parseInt(id)) };
        } else {
          requestBody = { canteenIds: parseInt(selectedCanteenId) };
        }
        const response = await post('v1/canteens/admin/menuitemsOfAll', requestBody);
        if (response) {
          setMenuItems(response);
          setIsLoading(false);
        } else {
          console.error('Response data is undefined:', response);
          setIsLoading(false);
        }
      } catch (error) {
        console.error('Error fetching menu items:', error);
        setIsLoading(false);
      }
    };
    
    fetchMenuItems();
  }, [selectedCanteenId, adminData.canteenId]);

  const filteredMenuItems = menuItems.filter((menuItem) =>
    Object.values(menuItem).some(
      (value) =>
        typeof value === 'string' && value.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const pageCount = Math.ceil(filteredMenuItems.length / itemsPerPage);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleAddMenuItem = async () => {
    setShowAddModal(true);
  };

  const handleEditMenuItem = (menuItem) => {
    setSelectedMenuItem(menuItem);
    setShowEditModal(true);
  };

  const handleClose = () => {
    setShowAddModal(false);
  };

  const handleAddSubmit = async (formData) => {
    try {
      // Create a new FormData object
      setIsLoading(true);
      const formDataToSend = new FormData();
      
      // Append the file data to the FormData object
      formDataToSend.append('file', formData.foodImage);
  
      // Upload the menu item image to the server
      const uploadResponse = await post('v1/images/upload', formDataToSend);
      const imageUrl = uploadResponse.imageUrl;
  
      // Check if the upload was successful
      if (imageUrl) {
        // Update the formData with the imageUrl
        formData.foodImage = imageUrl;
  
        // Make the POST request to add the menu item
        const response = await post('v1/menuitems', formData);
        console.log('Response data:', response);
  
        // Handle the response accordingly
        if (response) {
          setShowAddModal(false);
          setIsLoading(false);
          alert('Menu Item Added Succesfully!');
          window.location.reload();

        } else {
          console.error('Response data is undefined:', response);
          alert('Failed to add menu item. Please try again.');
          setIsLoading(false);
        }
      }
    } catch (error) {
      alert('Failed to add menu item. Please try again.');
      console.error('Error adding menu item:', error);
      setIsLoading(false);
    }
  };

  const handleEditSubmit = async (formData) => {
    try {
      setIsLoading(true);
      let updatedFormData = { ...formData };
  
      // Check if the food image is changed
      if (formData.foodImage instanceof File) {
        const formDataToSend = new FormData();
      
      // Append the file data to the FormData object
      formDataToSend.append('file', formData.foodImage);
        // Upload the new food image
        const uploadResponse = await post('v1/images/upload', formDataToSend);
        const imageUrl = uploadResponse.imageUrl;
        if (imageUrl) {
          updatedFormData.foodImage = imageUrl;
        }
      }
  
      // Make the PUT request to update the menu item
      const response = await put(`v1/menuitems/${formData.menuItemId}`, updatedFormData);
      console.log('Response data:', response);
      if (response) {
        // Assuming response contains the message
        console.log('Menu item updated successfully');
        setIsLoading(false);
        setShowEditModal(false);
        window.location.reload();
        alert('Menu Item Updated Succesfully!');
      } else {
        console.error('Error updating menu item:', response);
        alert('Failed to updating menu item. Please try again.');
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error updating menu item:', error);
      alert('Failed to updating menu item. Please try again.');
      setIsLoading(false);
    }
  };

  const handleDeleteMenuItem = async (menuItemId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this menu item?");
    if (confirmDelete) {
      try {
        // Disable the menu item by making a PUT request
        const response = await put(`v1/menuitems/disable/${menuItemId}`);
        alert('Menu item deleted successfully!')
        console.log('Response data:', response);
        // Handle success or failure accordingly
      } catch (error) {
        console.error('Error deleting menu item:', error);
      }
    }
  };

  const formatDateToDisplay = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
};


  return (
    <div>
      <h2>Menu</h2>
      <div className="search-bar">
        <SearchBar onSearch={handleSearch} />
      </div>
      {isLoading ? (
        <p>Loading menu items...</p>
      ) : (
        <div>
          <div className='top-area'>
            <select className='canteen-dropdown' onChange={handleCanteenChange} value={selectedCanteenId}>
              <option value="All">All Canteens</option>
              {canteenList.map((canteen) => (
              <option key={canteen.canteenId} value={canteen.canteenId}>
                {canteen.canteenName}
              </option>
              ))}
            </select>
          <button className='add-button' onClick={handleAddMenuItem}>Add Menu</button>
          </div>
          {showAddModal && (
            <AddMenuForm
              canteens={canteenList}
              onSubmit={handleAddSubmit}
              onClose={handleClose}
            />
          )}
          {showEditModal && (
            <EditMenuForm
              canteens={canteenList}
              menuItem={selectedMenuItem}
              onSubmit={handleEditSubmit}
              onClose={() => setShowEditModal(false)}
            />
          )}
          <table>
          <thead>
            <tr>
              <th>Food Image</th>
              <th>Food Name</th>
              <th>Food Price</th>
              <th>Stock Quantity</th>
              <th>Food Category</th>
              <th>Availability</th>
              <th>Creation Time</th>
              <th>Modification Time</th>
              <th>Modified By</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
          {filteredMenuItems
                .slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage)
                .map((menuItem) => (
                  <tr key={menuItem.menuItemId}>
                <td>
                    <img src={menuItem.foodImage} alt={menuItem.foodName} className='food-Image'/>
                  </td>
                <td>{menuItem.foodName}</td>
                <td>{menuItem.foodPrice}</td>
                <td>{menuItem.stockQuantity}</td>
                <td>{menuItem.foodCategory}</td>
                <td>{menuItem.availability ? 'Available' : 'Not Available'}</td>
                <td>{formatDateToDisplay(menuItem.menuCreationTime)}</td>
                <td>{formatDateToDisplay(menuItem.menuModificationTime)}</td>
                <td>{menuItem.modifiedByName}</td>
                <td>
                <button onClick={() => handleEditMenuItem(menuItem)}><BiEdit/></button>
                <button className='delete-button' onClick={() => handleDeleteMenuItem(menuItem.menuItemId)}><BiTrash/></button>
                </td>
              </tr>
                ))}
          </tbody>
        </table>
        <ReactPaginate
            previousLabel={<FaChevronLeft />}
            nextLabel={<FaChevronRight />}
            breakLabel={'...'}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName={'pagination'}
            activeClassName={'active'}
            forcePage={currentPage}
          />
        </div>
      )}
    </div>
  );
}

export default Menu;
