import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from '../PrivateRoute';
import AdminLogin from './AdminLogin';
import AdminPanel from './AdminPanel';

function App() {
  return (
      <Routes>
        <Route path="/admin-login" element={<AdminLogin />} />
        <Route
          path="/*"
          element={
            <PrivateRoute>
              <AdminPanel />
            </PrivateRoute>
          }
        />
      </Routes>
  );
}

export default App;
